import { cloneElement } from 'react';
import { withRouter } from 'react-router-dom';
import { PropertiesProvider } from './properties';

function ProviderComposer({ contexts, children }) {
    return contexts.reduceRight(
        (kids, parent) => cloneElement(parent, {
            children: kids,
        }),
        children,
    );
}

const ContextProvider = withRouter(({ children }) => (
    <ProviderComposer contexts={[<PropertiesProvider />]}>
        {children}
    </ProviderComposer>
));

export { ContextProvider };
