import { useEffect } from 'react';
import { loadReCaptcha } from 'react-recaptcha-google';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Sidebar as SidebarSemantic, Container } from 'semantic-ui-react';
import loadable from '@loadable/component';
import { ContextProvider } from './state/all';

import 'semantic-ui-css/semantic.css';
import 'rc-tabs/assets/index.css';
import './scss/main.scss';
import 'react-toastify/dist/ReactToastify.min.css';

import { Loading } from './loading';
import Errors from './errors';

const AsyncTopHeader = loadable(() => import(/* webpackChunkName: "topheader", webpackPrefetch: true */ './components/header/topheader'), {
    fallback: <Loading />,
});

const AsyncLeftSidebar = loadable(() => import(/* webpackChunkName: "sidebar", webpackPrefetch: true */ './components/sidebar/leftsidebar'), {
    fallback: <Loading />,
});

const AsyncFooter = loadable(() => import(/* webpackChunkName: "footer", webpackPrefetch: true */ './components/footer/footer'), {
    fallback: <Loading />,
});

const AsyncSpinner = loadable(() => import(/* webpackChunkName: "spinner", webpackPrefetch: true */ './components/spinner/spinner'), {
    fallback: <Loading />,
});

const AsyncHome = loadable(() => import(/* webpackChunkName: "home", webpackPrefetch: true */ './components/home/home-page'), {
    fallback: <Loading />,
});

const AsyncLogin = loadable(() => import(/* webpackChunkName: "login", webpackPrefetch: true */ './components/login/login-page'), {
    fallback: <Loading />,
});

const AsyncDashboard = loadable(() => import(/* webpackChunkName: "dashboard", webpackPrefetch: true */ './components/dashboard/dashboard-page'), {
    fallback: <Loading />,
});

const AsyncContent = loadable(() => import(/* webpackChunkName: "content", webpackPrefetch: true */ './components/content/content-page'), {
    fallback: <Loading />,
});

const AsyncSettings = loadable(() => import(/* webpackChunkName: "settings", webpackPrefetch: true */ './components/settings/settings-page'), {
    fallback: <Loading />,
});

const AsyncFeedback = loadable(() => import(/* webpackChunkName: "feedback", webpackPrefetch: true */ './components/feedback/feedback-page'), {
    fallback: <Loading />,
});

const AsyncQuotations = loadable(() => import(/* webpackChunkName: "quotations", webpackPrefetch: true */ './components/quotations/quotations-page'), {
    fallback: <Loading />,
});

const getMetaUrl = () => {
    const env = `${process.env.REACT_APP_ENV}`;
    switch (env) {
        case 'staging':
            return '/';
        case 'testing':
        case 'development':
            return '/zepre-admin';
        default:
            return '/';
    }
};


const App = props => {

    const { version } = props;

    useEffect(() => {
        loadReCaptcha();
    }, []);

    return (
        <Router basename={getMetaUrl()}>
            <div className="page-layout">
                <SidebarSemantic.Pushable>
                    <Errors>
                        <ContextProvider>
                            <AsyncLeftSidebar />
                            <SidebarSemantic.Pusher>
                                <AsyncTopHeader />
                                <main>
                                    <div className="main-content">

                                        <Container>
                                            <Route exact path="/" component={AsyncHome} />
                                            <Route path="/login" component={AsyncLogin} />
                                            <Route path="/dashboard" component={AsyncDashboard} />
                                            <Route path="/quotations" component={AsyncQuotations} />
                                            <Route path="/content" component={AsyncContent} />
                                            <Route path="/feedback" component={AsyncFeedback} />
                                            <Route path="/settings" component={AsyncSettings} />
                                            <AsyncFooter version={version} />
                                            <ToastContainer />
                                        </Container>
                                    </div>
                                </main>
                                <AsyncSpinner />
                            </SidebarSemantic.Pusher>
                        </ContextProvider>
                    </Errors>
                </SidebarSemantic.Pushable>
            </div>
        </Router>
    );
};

export default App;
