import CacheBuster from './CacheBuster';
import App from './app';

const Root = () => (
    <CacheBuster>
        {({ latestVersion, loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
                refreshCacheAndReload();
            }
            return <App version={latestVersion} />;
        }}
    </CacheBuster>
);

export default Root;
