import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const PropertiesContext = createContext({});

export const PropertiesProvider = (props) => {
    const {
        children,
    } = props;

    const [history, setHistory] = useState({});
    const [current_url, setCurrentUrl] = useState('');
    const [initial_url, setInitialUrl] = useState(null);
    const [show_spinner, setShowSpinner] = useState(false);
    const [logged_in, setLoggedIn] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [user, setUser] = useState(null);
    const [show_login_modal, setShowLoginModal] = useState(false);
    const [error, setError] = useState({});
    const [success, setSuccess] = useState({});
    const [form_type, setFormType] = useState('');
    const [modal_title, setModalTitle] = useState('');
    const [footer_title, setFooterTitle] = useState('ZEP-RE Admin');
    const [footer_powered_by, setFooterPoweredBy] = useState('Powered by ZEP-RE');
    const [is_mobile, setIsMobile] = useState(true);
    const [show_modal, setShowModal] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [show_sidebar, setShowSidebar] = useState(false);
    const [header_title, setHeaderTitle] = useState('ZEP-RE Quotation Management System');


    const authenticate = (value) => {
        setLoggedIn(value);
        setShowSidebar(value);

        if (value === true) {
            setShowLoginModal(false);

        }
    };

    const showLoginModal = (value, history) => {
        setShowLoginModal(value);

        if (history !== null) {
            setHistory(history);
        }
    };

    // Make the context object:
    const context = {
        history,
        setHistory,
        current_url,
        setCurrentUrl,
        initial_url,
        setInitialUrl,
        show_spinner,
        setShowSpinner,
        logged_in,
        setLoggedIn,
        redirect,
        setRedirect,
        user,
        setUser,
        show_login_modal,
        setShowLoginModal,
        error,
        setError,
        success,
        setSuccess,
        authenticate,
        showLoginModal,
        form_type,
        setFormType,
        modal_title,
        setModalTitle,
        footer_title,
        setFooterTitle,
        footer_powered_by,
        setFooterPoweredBy,
        is_mobile,
        setIsMobile,
        show_modal,
        setShowModal,
        processing,
        setProcessing,
        show_sidebar,
        setShowSidebar,
        header_title,
        setHeaderTitle,
    };

    // pass the value in provider and return
    return <PropertiesContext.Provider value={context}>{children}</PropertiesContext.Provider>;
};


PropertiesProvider.propTypes = {
    history: PropTypes.object,
    current_url: PropTypes.string,
    initial_url: PropTypes.string,
    show_spinner: PropTypes.bool,
    logged_in: PropTypes.bool,
    redirect: PropTypes.bool,
    user: PropTypes.object,
    show_login_modal: PropTypes.bool,
    error: PropTypes.object,
    success: PropTypes.object,
    form_type: PropTypes.string,
    modal_title: PropTypes.string,
    footer_title: PropTypes.string,
    footer_powered_by: PropTypes.string,
    header_title: PropTypes.string,
    is_mobile: PropTypes.bool,
    show_modal: PropTypes.bool,
    processing: PropTypes.bool,
    show_sidebar: PropTypes.bool,
};

PropertiesProvider.defaultProps = {
    history: {},
    current_url: '',
    initial_url: null,
    show_spinner: false,
    logged_in: false,
    redirect: false,
    user: null,
    show_login_modal: false,
    error: null,
    success: null,
    form_type: '',
    modal_title: '',
    footer_title: 'ZEP-RE Admin',
    footer_powered_by: 'Powered by ZEP-RE',
    header_title: 'ZEP-RE Quotation Management System',
    is_mobile: true,
    show_modal: false,
    processing: false,
    show_sidebar: false,
};
