import { Loader, Card } from 'semantic-ui-react';

export const Loading = ({ isLoading, error }) => {
    // Handle the loading state
    if (isLoading) {
        return <Loader active>Loading...</Loader>;
    }
    // Handle the error state
    if (error) {
        console.error(error);
        return (
            <Card centered fluid>
                <Card.Content>
                    <Card.Header>Loading error</Card.Header>
                    <Card.Meta><span className="date">Loading page....</span></Card.Meta>
                    <Card.Description>Sorry, there was a problem loading the page.</Card.Description>
                </Card.Content>
                <Card.Content extra />
            </Card>
        );
    }
    return null;
};
